import cn from 'classnames';
import * as React from 'react';
import { FC, Fragment } from 'react';
import styles from './AddButton.styles';
import AddButtonInner from './AddButtonInner';

interface Props {
  disabled?: boolean;
  withSmallHeight?: boolean;
  onClick(): void;
  children?: React.ReactNode;
  fullWidth?: boolean;
  withoutIcon?: boolean;
  dataMarkerModifier?: string;
  isBordered?: boolean;
}

const AddButton: FC<Props> = props => {
  const {
    disabled,
    withSmallHeight,
    onClick,
    children,
    isBordered = false,
    fullWidth = false,
    withoutIcon = false,
    dataMarkerModifier = '',
  } = props;

  const dataMarker = `Add to cart` + dataMarkerModifier;

  const handleClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    onClick();
  };

  return (
    <Fragment>
      <button
        onClick={handleClick}
        className={cn('AddButton', {
          AddButton_withSmallHeight: withSmallHeight,
          AddButton_fullWidth: fullWidth,
          AddButton_bordered: isBordered,
        })}
        type='button'
        data-testid='add-to-cart'
        data-marker={dataMarker}
        disabled={disabled}
      >
        <AddButtonInner withoutIcon={withoutIcon}>
          {children}
        </AddButtonInner>
      </button>
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default AddButton;
