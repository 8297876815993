const formatDateMonth = (date: string, language: string) => {
  return new Date(date)
    .toLocaleString(language, {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
    });
};

export default formatDateMonth;
