import typography from 'src/styles/typography';
import css from 'styled-jsx/css';
import { phone } from '../../../styles/media';

//@todo: refactor colors for this component
export default css`
  .AddButton {
    ${typography.btn}
    border-radius: var(--border-radius-roundBox);
    width: 64px;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border: none;
    height: 40px;
    color: var(--uiColors-Button-text);
    background: var(--uiColors-Button-background);
  }
  .AddButton:not(:disabled):hover {
    background: var(--uiColors-Button-hoverBackground);
  }
  .AddButton:active {
    background: var(--uiColors-Button-activeBackground);
  }
  .AddButton:disabled {
    background: var(--colors-brandLightGrey1);
    color: var(--colors-doveGray);
    pointer-events: none;
  }
  .AddButton_bordered {
    border: 1px solid var(--uiColors-Button-borderedDefaultBorder);
    background: var(--uiColors-Button-borderedDefaultBackground);
    color: var(--colors-mainBrand);
  }
  .AddButton_bordered:hover {
    background: var(--uiColors-Button-borderedDefaultHoverBackground);
    border-color: var(--uiColors-Button-borderedDefaultHoverBorder);
    color: var(--uiColors-Button-borderedDefaultHoverText);
  }
  .AddButton_bordered:active {
    background: var(--uiColors-Button-borderedDefaultActiveBackground);
    border-color: var(--uiColors-Button-borderedDefaultActiveBorder);
    color: var(--uiColors-Button-borderedDefaultActiveText);
  }
  .AddButton_withSmallHeight {
    height: 32px;
  }
  .AddButton_fullWidth {
    width: 100%;
  }

  @media ${phone} {
    .AddButton {
      padding-left: 0;
      padding-right: 0;
    }
  }
`;
