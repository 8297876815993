export default function shouldSetTintingModeToDependOnCart(
  itemIsAdded: boolean,
  amount: number,
  previousItemIsAdded: boolean,
  previousAmount: number,
) {
  const itemIsOut = !itemIsAdded && !previousItemIsAdded;
  
  if (itemIsOut) {
    return false;
  }

  const itemWasDeleted = !itemIsAdded && previousItemIsAdded;
  const itemWasAdded = itemIsAdded && !previousItemIsAdded;

  const amountWasChanged =
    itemIsAdded &&
    previousItemIsAdded &&
    amount !== previousAmount;

  return itemWasDeleted || itemWasAdded || amountWasChanged;
}


