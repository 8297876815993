import debounce from 'lodash/debounce';
import { ComponentType, useEffect, useMemo, useRef, useState } from 'react';

export interface WithTintingProps {
  isTintingMode: boolean;
  setTintingMode(): void;
}

function withTinting<P extends WithTintingProps>(Component: ComponentType<P>) {
  const WithTintingComponent = (props: Omit<P, keyof WithTintingProps>) => {
    const [isTintingMode, setIsTintingMode] = useState<boolean>(false);
    const tintingModeTimeoutRef = useRef<null | number>(null);

    useEffect(() => {
      return () => {
        if (tintingModeTimeoutRef.current) {
          window.clearTimeout(tintingModeTimeoutRef.current);
        }
      };
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const setTintingModeDebounce = useMemo(() => {
      const handleTintingMode = () => {
        if (tintingModeTimeoutRef.current) {
          return;
        }

        setIsTintingMode(true);
        tintingModeTimeoutRef.current = window.setTimeout(() => {
          setIsTintingMode(false);
          tintingModeTimeoutRef.current = null;
        }, 400);
      };

      return debounce(handleTintingMode, 500);
    }, []);

    return (
      <Component
        isTintingMode={isTintingMode}
        setTintingMode={setTintingModeDebounce}
        {...props as P}
      />
    );
  };

  const ComponentName = Component.displayName || Component.name || 'Component';
  WithTintingComponent.displayName = `WithTinting(${ComponentName})`;

  return WithTintingComponent;
}

export default withTinting;
