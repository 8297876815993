import classNames from 'classnames';
import * as React from 'react';
import useLocalization from 'src/services/localization/useLocalization';
import { styles } from './DiscountDisclaimer.styles';

export type DisclaimerVariant = 'subhead' | 'body' | 'small_caption' | 'product_tile'
export type DisclaimerPlace = 'cart' | 'productCard' | 'productTile'

interface Props {
  dueDate: string
  place?: DisclaimerPlace
  variant?: DisclaimerVariant
}

const DiscountDisclaimer: React.FunctionComponent<Props> = props => {
  const localize = useLocalization();
  const { dueDate, variant, place } = props;

  const [year, month, day] = dueDate.split('-');
  let dateLabel = `${localize('discount_available_when')} ${day}.${month}.${year}`;

  if(place === 'cart') {
    dateLabel = `${localize('discount_available_when_short')} ${day}.${month}.${year}`;
  }

  if(place === 'productTile') {
    dateLabel = ` ${localize('general_till')} ${day}.${month}`;
  }

  return (
    <span className={classNames(
      'DiscountDisclaimer', {
      'DiscountDisclaimer_subhead': variant === 'subhead',
      'DiscountDisclaimer_body': variant === 'body',
      'DiscountDisclaimer_smallCaption': variant === 'small_caption',
      'DiscountDisclaimer_productTile': variant === 'product_tile',
    })}
    data-marker='Promotion_until_date'
    >
      {dateLabel}
      <style jsx>{styles}</style>
    </span>
  );
};

DiscountDisclaimer.defaultProps = {
  variant: 'body',
  place: 'productCard',
};

export default DiscountDisclaimer;
