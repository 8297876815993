import cn from 'classnames';
import useLocalization from 'src/services/localization/useLocalization';
import formatDateMonth from 'src/utils/date/formatDateMonth';
import { WholesalePrice } from '../../../redux/apiTypes';
import { formatPrice } from '../../../utils/product/formatPrice';
import styles from './WholesalePrices.styles';

export interface WholesalePricesProps {
  wholesalePrices: WholesalePrice[];
  currency: string;
  measure: string;
  bundle: number;
  isBig?: boolean;
  isSmall?: boolean;
  withDate?: boolean;
  language: string;
}

const WholesalePrices = (props: WholesalePricesProps) => {
  const { wholesalePrices, currency, measure, isBig, isSmall, bundle, withDate, language } = props;
  const localize = useLocalization();

  return (
    <ul
      className={cn(
        'WholesalePrices', {
        'WholesalePrices_big': isBig,
        'WholesalePrices_small': isSmall,
      })}
    >
      {
        wholesalePrices.map(
          (item, index) => {
            const { min_qty, price, due_date } = item;

            const dateStr = withDate && due_date
              ? ` ${localize('general.to').toLocaleLowerCase()} ${formatDateMonth(due_date, language)}`
              : '';

            const priceInfo = [
              formatPrice(price),
              localize(`general.currency.${currency}`),
              localize('general.from'),
              min_qty,
              localize(`general.measure.${bundle && bundle > 1 ? 'bundle' : measure}`),
            ].join(' ');

            return (
              <li
                className='PriceWholesale__item'
                key={index}
                data-marker={`PriceWholesale_${index}`}
              >
                {`${priceInfo}${dateStr}`}
              </li>
            );
          },
        )
      }
      <style jsx>{styles}</style>
    </ul>
  );
};

WholesalePrices.defaultProps = {
  wholesalePrices: [],
  currency: '',
  measure: '',
  isBig: false,
  isSmall: false,
  bundle: 1,
};

export default WholesalePrices;
