import { DeliveryType, Product } from "src/redux/apiTypes";
import { getAvailability } from "src/redux/cart/general/helpers/getAvailability";
import useLocalization from 'src/services/localization/useLocalization';

interface Params {
  product: Product;
  deliveryType: DeliveryType;
}

export const useProductTileAvailability = ({ product, deliveryType }: Params) => {
  const localize = useLocalization();
  const isAvailable = getAvailability(product, deliveryType);

  let availabilityWarning: null | string = null;

  if (!product.in_stock) {
    availabilityWarning = localize('product_not_in_stock');
  }

  if (product.is_uber_item) {
    availabilityWarning = localize('product_not_in_store');
  }

  if (product.in_stock && !isAvailable) {
    availabilityWarning = localize('np_products_unavailable');
  }

  return { availabilityWarning, isAvailable };
};
