import { useEffect, useRef } from "react";
import shouldSetTintingModeToDependOnCart from "src/HOCs/withTinting/shouldSetTintingModeToDependOnCart";
import usePrevious from "src/hooks/usePrevious/usePrevious";

interface TintingParams {
  ean: string;
  amount: number;
  itemIsAdded: boolean;
  setTintingMode(): void;
}

export const useProductTileTinting = (params: TintingParams) => {
  const {
    ean,
    amount,
    itemIsAdded,
    setTintingMode,
  } = params;

  const isInitialMount = useRef(true);
  const previousItemIsAdded = usePrevious(itemIsAdded);
  const previousAmount = usePrevious(amount);
  const prevEan = usePrevious(ean);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
      return;
    }

    if (!ean || ean !== prevEan) {
      return;
    }

    const withTintingMode = shouldSetTintingModeToDependOnCart(
      itemIsAdded,
      amount,
      previousItemIsAdded,
      previousAmount,
    );

    if (withTintingMode) {
      setTintingMode();
    }
  }, [amount, ean, itemIsAdded, prevEan, previousAmount, previousItemIsAdded, setTintingMode]);
};
