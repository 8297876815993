import { Fragment, ReactNode } from 'react';
import styles from './AddButtonInner.styles';

interface AddButtonInnerProps {
  withoutIcon?: boolean;
  children?: ReactNode;
}

const AddButtonInner = (props: AddButtonInnerProps) => {
  const { withoutIcon, children } = props;

  return (
    <Fragment>
      {!children ? (
        <span className='AddButtonInner__plus'>+</span>
      ) : (
        <span className='AddButtonInner__text'>
          {children}
        </span>
      )}

      {!withoutIcon && (
        <span
          className='icon-basket AddButtonInner__icon'
          data-testid='icon_basket'
        />
      )}
      <style jsx>{styles}</style>
    </Fragment>
  );
};

export default AddButtonInner;
